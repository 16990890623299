// See: https://github.com/greenstone/keadatabase-back/blob/master/src/synchronise/bands.py#L13

const colours = {
  black: {
    name: "Black",
    hex: "#000000",
  },
  blue: {
    name: "Blue",
    hex: "#0000FF",
  },
  brown: {
    name: "Brown",
    hex: "#A52A2A",
  },
  green: {
    name: "Green",
    hex: "#008000",
  },
  grey: {
    name: "Grey",
    hex: "#808080",
  },
  limegreen: {
    name: "Lime Green",
    hex: "#00FF00",
  },
  metal: {
    name: "Metal",
    hex: "linear-gradient(to right,#808080, #ddd, #808080)",
  },
  orange: {
    name: "Orange",
    hex: "#FFA500",
  },
  pink: {
    name: "Pink",
    hex: "#FFC0CB",
  },
  purple: {
    name: "Purple",
    hex: "#800080",
  },
  red: {
    name: "Red",
    hex: "#FF0000",
  },
  silver: {
    name: "Silver",
    hex: "linear-gradient(to right,#C6C7BE, #eee, #C6C7BE)",
  },
  white: {
    name: "White",
    hex: "#FFFFFF",
  },
  yellow: {
    name: "Yellow",
    hex: "#FFFF00",
  },
  lightblue: {
    name: "Light Blue",
    hex: "#ADD8E6",
  },
};

export default colours;
